import { useState, useEffect } from "react";
import { Button, Select, Label } from "flowbite-react";
import { getCountries, getTopics, getSubtopics } from "../services/api"; // Add getSubtopics

function BannerForm() {
  const [countries, setCountries] = useState([]);
  const [topics, setTopics] = useState([]); // New state for topics
  const [subtopics, setSubtopics] = useState([]); // State for subtopics

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedTopic, setSelectedTopic] = useState(""); // State for selected topic
  const [selectedSubtopic, setSelectedSubtopic] = useState(""); // State for selected subtopic

  // Fetch countries when component mounts
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getCountries();
        setCountries(response.data);
      } catch (error) {
        console.error("Error fetching countries", error);
      }
    };

    fetchCountries();
  }, []);

  // Fetch topics when component mounts
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await getTopics();
        setTopics(response.data); // Assuming topics come as an array
      } catch (error) {
        console.error("Error fetching topics", error);
      }
    };

    fetchTopics();
  }, []);

  // Inside BannerForm.jsx
  useEffect(() => {
    if (selectedTopic) {
      const fetchSubtopics = async () => {
        try {
          const response = await getSubtopics(selectedTopic); // Pass selectedTopic to fetch subtopics
          setSubtopics(response.data); // Assuming subtopics come as an array
        } catch (error) {
          console.error("Error fetching subtopics", error);
        }
      };
      fetchSubtopics();
    } else {
      setSubtopics([]); // Clear subtopics if no topic is selected
    }
  }, [selectedTopic]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleTopicChange = (e) => {
    setSelectedTopic(e.target.value); // Update selected topic
  };

  const handleSubtopicChange = (e) => {
    setSelectedSubtopic(e.target.value); // Update selected subtopic
  };

  return (
    <div className="w-full">
      <section
        className="bg-center bg-cover bg-gray-700 bg-blend-multiply py-12 lg:py-16 h-auto"
        style={{
          backgroundImage:
            "url('https://fourwaves.com/media/jjtaaupz/conference-speaker.jpg')",
        }}
      >
        <div className="px-4 py-4 mx-auto max-w-screen-xl text-center bg-white rounded-lg">
          <form className="grid grid-cols-1 md:grid-cols-5 gap-4 items-center">
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="country" value="Select Country" />
              </div>
              <Select
                id="country"
                value={selectedCountry}
                onChange={handleCountryChange}
                required
              >
                <option value="" disabled>
                  Select a country
                </option>
                {countries.map((country) => (
                  <option key={country.id} value={country.country}>
                    {country.country}
                  </option>
                ))}
              </Select>
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="topic" value="Select Topic" />
              </div>
              <Select
                id="topic"
                value={selectedTopic}
                onChange={handleTopicChange}
                required
              >
                <option value="" disabled>
                  Select a topic
                </option>
                {topics.map((topic) => (
                  <option key={topic.id} value={topic.id}>
                    {" "}
                    {/* Use topic.id here */}
                    {topic.category}
                  </option>
                ))}
              </Select>
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="subtopic" value="Select SubTopic" />
              </div>
              <Select
                id="subtopic"
                value={selectedSubtopic}
                onChange={handleSubtopicChange}
                required
                disabled={!subtopics.length} // Disable subtopics if none available
              >
                <option value="" disabled>
                  Select a subtopic
                </option>
                {subtopics.map((subtopic) => (
                  <option key={subtopic.id} value={subtopic.topic}>
                    {subtopic.topic}
                  </option>
                ))}
              </Select>
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="monthyear" value="Month & Year" />
              </div>
              <Select id="monthyear" required>
                <option value="" disabled>
                  Select month & year
                </option>
                <option value="oct2024">October 2024</option>
                <option value="nov2024">November 2024</option>
                <option value="dec2024">December 2024</option>
              </Select>
            </div>

            <div className="w-auto mt-7 block">
              <Button
                gradientMonochrome="info"
                type="submit"
                className="w-full"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default BannerForm;

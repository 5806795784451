import { Button, Navbar, NavbarBrand, NavbarCollapse, NavbarLink, NavbarToggle } from "flowbite-react";
import { BsFacebook, BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

function NavbarComponent() {
  return (
		<Navbar fluid rounded>
			<NavbarBrand href="./">
				<img
					src="https://www.conferencealerts.org/images/logo.webp"
					className="h-12 md:h-20"
					alt="Conference Alert Logo"
				/>
			</NavbarBrand>
			<div className="flex md:order-2 items-center space-x-4">
				<Button gradientMonochrome="info">Login</Button>
				<div className="flex space-x-2">
					<a
						href="https://www.facebook.com/conferencealerts.org/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<BsFacebook className="text-xl text-blue-600 hover:text-blue-800" />
					</a>
					<a
						href="https://twitter.com/Confalerts_org"
						target="_blank"
						rel="noopener noreferrer"
					>
						<BsTwitter className="text-xl text-blue-400 hover:text-blue-600" />
					</a>
					<a
						href="https://www.instagram.com/conferencealertsorg/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<BsInstagram className="text-xl text-pink-500 hover:text-pink-700" />
					</a>
					<a
						href="https://www.linkedin.com/in/conferencealertsorg/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<BsLinkedin className="text-xl text-blue-700 hover:text-blue-900" />
					</a>
				</div>
				<NavbarToggle />
			</div>
			<NavbarCollapse>
				<NavbarLink as={Link} to="/" active>
					Home
				</NavbarLink>
				<NavbarLink as={Link} to="/about">About</NavbarLink>
				<NavbarLink as={Link} to="/add-event">Add Event</NavbarLink>
				<NavbarLink as={Link} to="/subscribe">Subscribe</NavbarLink>
				<NavbarLink href="#">Contact US</NavbarLink>
				<NavbarLink href="#">Blog</NavbarLink>
			</NavbarCollapse>
		</Navbar>
	);
}

export default NavbarComponent;

import React from "react";
import NavbarComponent from "../components/NavbarComponent";
import CountryForm from "../components/BannerForm";
import FooterComponent from "../components/FooterComponent";

function About() {
  return (
    <div>
      <NavbarComponent />
      <CountryForm />
      <h1>About Page</h1>
      <p>This is the About Page of our website.</p>
      <FooterComponent />
    </div>
  );
}

export default About;

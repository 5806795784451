import React from "react";
import NavbarComponent from "../components/NavbarComponent";
import CountryForm from "../components/BannerForm";
import ConferenceAlertSection from "../components/ConferenceAlertSection";
import SidebarWithTopics from "../components/TopicSidebar";
import ContinentCountryGrid from "../components/ContinentsGrid";
import Calendar from "../components/Calendar";
import FooterComponent from "../components/FooterComponent";

function Home() {
  return (
    <div>
      <NavbarComponent />
      <CountryForm />
      <ConferenceAlertSection />
      <ContinentCountryGrid />
      <SidebarWithTopics/>
      <Calendar/>
      <FooterComponent />
    </div>
  );
}

export default Home;

import { Label, TextInput } from "flowbite-react";

const FieldWithIcon = ({ icon: Icon, id, value, onChange, type, label, placeholder, required }) => {
  return (
    <div className="flex flex-col gap-2">
      {/* Icon and label row */}
      <div className="flex items-center gap-3">
        <Icon className="text-xl text-gray-600" />
        <Label htmlFor={id} className="text-lg text-gray-800">
          {label}
        </Label>
        {required && <span className="text-orange-400">*</span>}
      </div>

      {/* Input field row */}
      <TextInput
        id={id}
        type={type}
        name={id}
        value={value}
        onChange={onChange}
        className="w-full rounded-xl shadow-lg"
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
};

export default FieldWithIcon;

import { useState} from "react";
import {generateRandomString,dbformatCurrentDate} from '../utils/helperUtils'
import {postEvent} from "../services/api";

const useAddEventForm = () => {

  const current_date = dbformatCurrentDate();
  const status = 'New';
  const val_code = generateRandomString();
  const org_type = "Organizer";
  const logo = 'fg';
  const event_type = 'Conference';

  const [formData, setFormData] = useState({
    reg_date: current_date,
    status: status,
    val_code: val_code,
    logo: logo,
    org_type,
    organizerName: "",
    organizerEmail: "",
    organizationName:"",
    password: "",
    confirmPassword: "",
    eventName: "",
    eventType: event_type,
    eventCategory: "",
    eventTopic: "",
    eventCountry: "",
    eventState: "",
    eventCity: "",
    eventOrgSociety: "",
    contactPerson: "",
    eventEnqueryEmail: "",
    website: "",
    eventStartDate: "",
    eventEndDate: "",
    abstractDeadline: "",
    eventDescription: "",
    eventKeywords: [],
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validatePasswords = () => {
    if (formData.password !== formData.confirmPassword) {
      return "Passwords do not match.";
    }
    if (formData.password.length < 6) {
      return "Password must be at least 6 characters long.";
    }
    return null;
  };

  const validateDates = () => {
    const errors = {};
    const { eventStartDate, eventEndDate, abstractDeadline } = formData;
  
    if (eventStartDate && eventEndDate && new Date(eventStartDate) >= new Date(eventEndDate)) {
      errors.eventEndDate = "Event End Date must be greater than Event Start Date.";
    }
  
    if (eventStartDate && abstractDeadline && new Date(abstractDeadline) >= new Date(eventStartDate)) {
      errors.abstractDeadline = "Abstract Deadline must be earlier than Event Start Date.";
    }
  
    return errors; // Always return an object
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordError = validatePasswords();
    if (passwordError) {
      setErrors((prev) => ({ ...prev, password: passwordError }));
      alert(passwordError); 
      return;
    }

    const dateErrors = validateDates();
    if (Object.keys(dateErrors).length > 0) {
      setErrors((prev) => ({ ...prev, ...dateErrors }));      
      const errorMessages = Object.values(dateErrors).join("\n");
      alert(errorMessages);
      return;
    }
 
    if (true) {
      setIsSubmitting(true);
      try {
        // Combine refs and state for final data
        const finalData = {
          ...formData,
          eventStartDate: dbformatCurrentDate(formData.eventStartDate),
          eventEndDate: dbformatCurrentDate(formData.eventEndDate),
          abstractDeadline: dbformatCurrentDate(formData.abstractDeadline),
          eventKeywords: formData.eventKeywords.join(','),
        };

        const response = await postEvent(finalData);
  
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        console.log(response.body);
        
  
        const result = await response.json();
        console.log("Form submission result:", result);
        alert("Form submitted successfully!");
  
        setFormData({
          organizerName: "",
          organizerEmail: "",
          organizerAddress: "",
          organizerCity: "",
          password: "",
          confirmPassword: "",
          eventName: "",
          eventType: "",
          eventCategory: "",
          eventTopic: "",
          eventCountry: "",
          eventState: "",
          eventCity: "",
          eventStartDate: "",
          eventEndDate: "",
          abstractDeadline: "",
          eventDescription: "",
          eventKeywords: [],
        });
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("An error occurred while submitting the form. Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return {
    formData,
    errors,
    isSubmitting,
    setFormData,
    handleSubmit,
  };
};

export default useAddEventForm;

import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getCountries = () => {
  return axios.get(`${BASE_URL}/country`);
};

export const getTopics = () => {
  return axios.get(`${BASE_URL}/topic`);
};

export const getSubtopics = (topicId) => {
  return axios.get(`${BASE_URL}/subtopic/${topicId}`);
};

export const createUser = (data) => {
  return axios.post(`${BASE_URL}/user`, { data });
};

export const postEvent = (data) => {
  return axios.post(`${BASE_URL}/events`,data);
};
import React, { useState, useEffect } from "react";
import { getTopics, getSubtopics } from "../services/api";

const SidebarWithTopics = () => {
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);

  // Fetch topics on component mount
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await getTopics();
        setTopics(response.data);
        setSelectedTopic(response.data[0]?.id); // Set the first topic as active by default
      } catch (error) {
        console.error("Error fetching topics", error);
      }
    };

    fetchTopics();
  }, []);

  // Fetch subtopics when a topic is selected
  useEffect(() => {
    if (selectedTopic) {
      const fetchSubtopics = async () => {
        try {
          const response = await getSubtopics(selectedTopic);
          setSubtopics(response.data);
        } catch (error) {
          console.error("Error fetching subtopics", error);
        }
      };

      fetchSubtopics();
    }
  }, [selectedTopic]);

  return (
    <div className="container mx-auto p-6">
      <div className="flex flex-col md:flex-row h-auto">
        {/* Left Sidebar - Topics */}
        <div className="md:w-1/4 w-full bg-cyan-700 p-4 text-white rounded-l-lg">
          <ul className="space-y-4">
            {topics.map((topic) => (
              <li key={topic.id}>
                <button
                  onClick={() => setSelectedTopic(topic.id)}
                  className={`w-full text-left py-2 px-4 bg-cyan-600 hover:bg-cyan-500 rounded-lg ${
                    selectedTopic === topic.id ? "bg-cyan-500" : ""
                  }`}
                >
                  {topic.category}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Right Content - Subtopics Grid with Scrollbar */}
        <div className="md:w-3/4 w-full bg-gray-100 p-6 rounded-r-lg">
          {selectedTopic && subtopics.length ? (
            <div>
              <h3 className="text-xl font-semibold mb-4">
                {topics.find((t) => t.id === selectedTopic)?.category}
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 overflow-y-auto h-[36rem]">
                {subtopics.map((subtopic) => (
                  <div
                    key={subtopic.id}
                    className="p-4 bg-white rounded-lg shadow-md hover:bg-cyan-100 cursor-pointer"
                  >
                    <p className="text-gray-700 font-semibold">{subtopic.topic}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p className="text-gray-500">Please select a topic to view subtopics.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SidebarWithTopics;
import { useState } from "react";

function ConferenceAlertSection() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md w-full">
      <p className="text-gray-800 text-justify">
        ConferenceAlert offers a list of upcoming international conferences, events, seminars, and webinars happening in 2024 and 2025. Subscribe to Conference Alerts and stay updated with the latest academic and professional conferences worldwide.
      </p>

      {isExpanded && (
        <p className="text-gray-800 mt-2 text-justify">
          We have more than 100,000 registered subscribers from diverse academic and non-academic backgrounds...
        </p>
      )}

      <button
        onClick={toggleReadMore}
        className="mt-4 text-secondary hover:underline hover:text-hover"
      >
        {isExpanded ? 'Read Less' : 'Read More'}
      </button>
    </div>
  );
}

export default ConferenceAlertSection;

import React, { useState, useEffect } from 'react';
import { Accordion } from 'flowbite-react';
import { getCountries } from "../services/api"; // Add getSubtopics

const ContinentCountryGrid = () => {
  const [continentsWithCountries, setContinentsWithCountries] = useState({});

  useEffect(() => {
    const fetchContinentsAndCountries = async () => {
      try {
        const response = await getCountries();
        
        // Transform the data into an object grouping countries by continent_name
        const groupedData = response.data.reduce((acc, item) => {
          if (item.continent_name) {
            if (!acc[item.continent_name]) {
              acc[item.continent_name] = [];
            }
            acc[item.continent_name].push(item.country);
          }
          return acc;
        }, {});

        setContinentsWithCountries(groupedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchContinentsAndCountries();
  }, []);

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold text-center mb-6">Conference By Country</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6">
        {Object.entries(continentsWithCountries).map(([continent, countries], index) => (
          <div
            key={index}
            className="bg-blue-100 p-4 rounded-lg shadow-md"
          >
            <Accordion>
              <Accordion.Panel>
                <Accordion.Title>
                  <div className="bg-gradient-to-r from-cyan-600 to-cyan-700 text-white py-2 px-4 rounded-lg">
                    {continent}
                  </div>
                </Accordion.Title>
                <Accordion.Content>
                  <ul className="list-disc pl-4 max-h-36rem overflow-auto h-48">
                    {countries.map((country, idx) => (
                      <li key={idx}>{country}</li>
                    ))}
                  </ul>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContinentCountryGrid;

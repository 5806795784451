import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import AddEvent from "./pages/Add-Event";
import Subscribe from "./pages/Subscribe";
// import Login from './pages/Login';

function App() {
  return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/add-event" element={<AddEvent />} />
				<Route path="/subscribe" element={<Subscribe />} />
				{/* <Route path="/login" element={<Login />} /> */}
			</Routes>
		</Router>
	);
}

export default App;
import React from 'react';

const items = Array.from({ length: 12 }, (_, i) => i + 1); // Create 30 items

const Calendar = () => {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold text-center mb-6">Calendar</h2>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex justify-center items-center h-24 bg-cyan-600 text-white rounded-lg shadow-md"
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;

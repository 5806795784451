import React from "react";
import NavbarComponent from "../components/NavbarComponent";
import CountryForm from "../components/BannerForm";
import FooterComponent from "../components/FooterComponent";
import { HR } from "flowbite-react";

function Subscribe() {
	return (
		<div>
			<NavbarComponent />
			<CountryForm />
			<div className="container mx-auto p-6 md:px-16 py-4">
				<div className="grid md:grid-cols-3 grid-cols-1">
					<div className="md:col-span-2">
						<div className="bg-white rounded-lg shadow-lg overflow-hidden pb-6">
							<div class="bg-cyan-700 text-center py-2 px-6 rounded-t-lg">
								<h1 className="text-2xl font-bold text-white">
									Add New Events
								</h1>
							</div>
							<form className="grid md:grid-cols-2 grid-cols-1 gap-4 max-w-4xl mx-auto p-4">
								<h3 class="font-medium text-cyan-700 md:text-2xl pb-2 md:col-span-2">
									Event Organizer Details
									<HR className="mb-2 my-2" />
								</h3>
							</form>
						</div>
					</div>
				</div>
			</div>
			<FooterComponent />
		</div>
	);
}

export default Subscribe;